<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:27:52
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-18 10:01:00
 * @Description: 全局搜索弹窗
-->
<template>
  <el-dialog class="search-dialog" :visible="visible" fullscreen top="60px" append-to-body :close-on-click-modal="false" @close="visible=false">
    <template slot="title">
      <div class="dialog-title">
        <span>全局搜索</span>
        <el-input ref="search-bar" :clearable="true" placeholder="请输入" v-model="txt" size="mini" @input="handleInputChange">
          <template slot="append">
            <en-icon name="sousuolansousuo" size="small" color="#a9b5c6" @click.native="handleIsSearch"></en-icon>
          </template>
        </el-input>
      </div>
    </template>
    <screen-model @handleScreen="handleScreen" ref="scMd"></screen-model>
    <div class="result-lis" v-en-loading="loading">
      <en-result v-show="!resultList.length" type="noData" subTitle="暂无内容，请设置筛选条件试试~"></en-result>
      <contract-item v-for="itm in resultList" :key="itm.id" :node="itm"></contract-item>
    </div>
    <en-pagination :page-model="pageModel" @change="handlePageChange"></en-pagination>
  </el-dialog>
</template>

<script>
import { debounce } from "lodash";
import { lawService } from "@/api";
import screenModel from "../screenModel";
import contractItem from "../contractItem";

export default {
  name: "ScreenAlert",
  components: { screenModel, contractItem },
  data() {
    return {
      txt: "", // 搜索关键字
      visible: false,
      loading: false,
      resultList: [],
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: 0,
        totalPages: 0
      },
      seParam: {
        pagesize: 10,
        page: 1,
        key: "",
        category: "",
        isMultiple: "",
        docType: "",
        isGovernment: ""
      }
    };
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        this.txt = "";
        Object.assign(this.pageModel, this.$options.data().pageModel);
        Object.assign(this.seParam, this.$options.data().seParam);
        this.$nextTick(() => {
          this.$refs.scMd.resetData();
          this.$refs["search-bar"].focus();
        });

        this.queryData();
      }
    }
  },
  methods: {
    // 分页器事件
    handlePageChange({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryData();
    },
    // 查询数据
    async queryData() {
      try {
        this.loading = true;
        this.seParam.page = this.pageModel.pageNo;
        this.seParam.pagesize = this.pageModel.pageSize;
        const rsp = await lawService.searchTemplates(this.seParam);
        this.loading = false;
        this.pageModel.total = rsp.Total;
        this.pageModel.totalPages = Math.ceil(Number(rsp.Total) / this.pageModel.pageSize);
        this.resultList = [...rsp.Items];
      } catch (error) {
        this.loading = false;
      }
    },
    // 筛选查询
    handleScreen(data) {
      Object.assign(this.seParam, data);
      this.queryData();
    },
    // 关键字查询
    handleInputChange(value) {
      this.emitSearch(value, this);
    },
    emitSearch: debounce((value, _this) => {
      _this.pageModel.pageNo = 1;
      _this.pageModel.pageSize = 10;
      _this.seParam.key = value;
      _this.queryData();
    }, 350)
  }
};
</script>

<style lang='scss' scoped>
@import "@/styles/vars.scss";
.search-dialog {
  color: #333333;
  margin-top: 60px;
  font-size: 12px;
  & /deep/ .el-dialog__header {
    border-bottom: 1px solid #e8ecf2;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
  }
  .dialog-title{
    display: flex;
    align-items: center;
    .el-input{
      width: 356px;
      margin-left: 10px;
      & /deep/ .el-input-group__append{
        padding: 0 8px;
        background: #FEFFFD;
      }
    }
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    height: calc(100% - 60px);
    .result-lis {
      height: calc(100% - 200px);
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      @include hscrollbar;
      .nodata {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
