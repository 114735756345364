<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:25:19
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 18:10:32
 * @Description: file content
-->
<template>
  <div class="container">
    <div :class="['node-item', node.cur ? 'cur' : '']" @click="handleNodeClick">
      <div class="node-item-wrap" :style="{'margin-left': `${node.level*20}px`}">
        <en-icon size="small" :name="!node.open ? 'jia' : 'jian'" v-show="node.hasChildren" @click.native.stop="node.open = !node.open"></en-icon>
        <en-icon size="small" v-show="!node.hasChildren"></en-icon>
        <span class="name">{{ node.name }}</span>
      </div>
    </div>
    <div class="node-item-group" v-show="node.open">
      <tree-node v-for="itm in node.childrenList" :key="itm.id" :node="itm"></tree-node>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeNode",
  props: {
    node: {
      type: Object,
      default: () => {}
    }
  },
  inject: ["$home"],
  data() {
    return {};
  },
  methods: {
    /**
     * @description 点击子目录
     */
    handleNodeClick() {
      const { id } = this.node;
      this.$home.handleNodeClick(id);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .node-item {
    font-size: 12px;
    text-align: left;
    color: #636c78;
    padding: 10px 0;
    .en-icon {
      margin: 0 10px;
      vertical-align: middle;
    }
    .name {
      display: inline-block;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
    &:hover {
      background: #f5f8fd;
    }
    &.cur {
      color: #3e90fe;
    }
    .node-item-wrap{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
