var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "contract-item",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleCheckDetail.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "contract-content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "theme" }, [
            _vm._v(_vm._s(`#${_vm.node.Number} ${_vm.node.Title}`)),
          ]),
          _c("div", { staticClass: "tag" }),
          _c("div", {
            staticClass: "brief",
            domProps: { innerHTML: _vm._s(_vm.node.Brief) },
          }),
          _c(
            "div",
            { staticClass: "time" },
            [
              _c("en-icon", {
                staticStyle: { width: "16px", height: "16px" },
                attrs: { name: "shijian-zhuti" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.node.PublishedOn.slice(0, 10)))]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.node.AuthorId,
                  expression: "node.AuthorId",
                },
              ],
              staticClass: "author",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCheckAuthor.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "author-log" },
                [
                  _c("el-avatar", {
                    attrs: { src: _vm.node.AuthorAvatar, size: 26 },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "author-name" }, [
                _vm._v(_vm._s(_vm.node.AuthorName)),
              ]),
            ]
          ),
          Number(_vm.node.Price)
            ? _c("div", { staticClass: "price" }, [
                _vm._v(
                  "￥" +
                    _vm._s(
                      _vm.node.Price ? Number(_vm.node.Price).toFixed(2) : ""
                    )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "contract-border" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }