<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:28:52
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 17:29:04
 * @Description: 筛选模块
-->
<template>
  <div class="screen-wrap">
    <el-form ref="form" :model="form" label-width="60px">
      <el-form-item label="文档类型">
        <el-checkbox-group v-model="form.docType">
          <el-checkbox v-for="itm in category" :key="itm.key" :label="itm.label" @change="(value) => hanleChange1(value, itm.key)"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="成套文本">
        <el-checkbox-group v-model="form.isMultiple">
          <el-checkbox :label="true" @change="(value) => hanleChange2(value, true)">是</el-checkbox>
          <el-checkbox :label="false" @change="(value) => hanleChange2(value, false)">否</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="政府示范">
        <el-checkbox-group v-model="form.isGovernment">
          <el-checkbox :label="true" @change="(value) => hanleChange3(value, true)">是</el-checkbox>
          <el-checkbox :label="false" @change="(value) => hanleChange3(value, false)">否</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ScreenModel",
  data() {
    return {
      form: {
        docType: [],
        isMultiple: [],
        isGovernment: []
      },
      category: [
        { key: 1, label: "合同(两方)" },
        { key: 2, label: "合同(三方及以上)" },
        { key: 3, label: "律师文书" },
        { key: 4, label: "规章制度" },
        { key: 5, label: "证明|声明|授权" },
        { key: 6, label: "收据|借据|欠条" },
        { key: 7, label: "通知|申请|决定" },
        { key: 8, label: "办事流程|清单 " },
        { key: 9, label: "其它类型" },
        { key: 10, label: "行业模板(成套)" },
        { key: 11, label: "单项法律事务(成套)" }
      ]
    };
  },
  mounted() {
    Object.assign(this.form, this.$options.data().form);
  },
  methods: {
    // 文档类型
    hanleChange1(value, key) {
      this.form.docType = [];
      if (value) {
        const cate = this.category.filter((itm) => itm.key === key)[0];
        this.form.docType.push(cate.label);
      }
      this.emitSearch();
    },
    // 成套文本
    hanleChange2(value, key) {
      this.form.isMultiple = [];
      if (value) {
        this.form.isMultiple.push(key);
      }
      this.emitSearch();
    },
    // 政府示范
    hanleChange3(value, key) {
      this.form.isGovernment = [];
      if (value) {
        this.form.isGovernment.push(key);
      }
      this.emitSearch();
    },
    // 触发搜索
    emitSearch() {
      const params = {
        docType: this.form.docType[0] || "",
        isMultiple: this.form.isMultiple.length ? this.form.isMultiple[0] : "",
        isGovernment: this.form.isGovernment.length ? this.form.isGovernment[0] : ""
      };
      this.$emit("handleScreen", params);
    },
    // 重置初始数据
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-wrap {
  color: #333333;
  background: #f5f7fa;
  padding: 0 20px;
  & /deep/ .el-form-item__content{
    text-align: left;
  }
  & /deep/ .el-form-item__label,
  & /deep/ .el-checkbox__label {
    font-size: 12px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
