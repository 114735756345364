<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:18:31
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-18 10:03:00
 * @Description: 法律知识库--文档详情
-->
<template>
  <div class="detail-wrap">
    <div class="detail-box" v-en-loading="loading">
      <!-- 操作按钮 -->
      <div class="operate-row">
        <div class="avatar" v-show="detail.AuthorId">
          <el-avatar
            :src="detail.AuthorAvatar"
            :size="100"
            fit="cover"
            @click.native="handleCheckAuthor"
          ></el-avatar>
          <span class="author-name" style="font-size: 16px">{{
            detail.AuthorName || ""
          }}</span>
        </div>
        <div class="btn-group">
          <el-button
            style="width: 72px"
            size="small"
            class="save"
            @click="handleSave"
            :disabled="Object.keys(detail).length === 0"
            >另存</el-button
          >
          <el-button
            size="small"
            class="download"
            @click="handleDownload"
            :disabled="Object.keys(detail).length === 0"
            >下载到本地</el-button
          >
        </div>
      </div>
      <!-- 基本信息 标题 -->
      <div class="basic">
        <div class="basic-left">
          <div class="basic-title">
            {{ `#${detail.Number || ""} ${detail.Title || ""}` }}
          </div>
          <div class="basic-category">
            <div class="cate-lis">
              {{
                detail.TemplateCategoryNames
                  ? detail.TemplateCategoryNames.join(" ")
                  : ""
              }}
            </div>
            <div class="word-num">
              <en-icon name="zi" color="#26c393" size="mini"></en-icon>
              {{ detail.Words || "" }}
            </div>
          </div>
        </div>
        <div class="basic-right">
          <en-icon
            name="shijian-zhuti"
            size="mini"
          ></en-icon>
          <span class="time-label">{{
            detail.PublishedOn ? detail.PublishedOn.slice(0, 10) : ""
          }}</span>
        </div>
      </div>
      <!-- 简要信息 -->
      <div class="brief" v-html="detail.Brief"></div>
      <!-- 正文 -->
      <div class="detail-content" v-html="detail.Content"></div>
      <!-- 链接 addby:梁平贤 -->
      <div class="link" v-if="showLinkCount > 0">
        <div class="link-placeholder">
          <span class="color-block"></span>
          <span class="text-block">{{ `包含${showLinkCount}个文本` }}</span>
        </div>
        <div
          v-for="(data, index) in detail.MultipleTemplates"
          :key="index"
          :class="{
            'link-bold': data.Number === 0,
            'link-normal': data.Number !== 0,
          }"
          @click="handleJumpToLink(data)"
        >
          {{ data.Number === 0 ? data.Title : `#${data.Number} ${data.Title}` }}
        </div>
      </div>
    </div>

    <!-- 另存为 提示 -->
    <el-dialog class="save-tip-alert" :visible.sync="visible" width="700px">
      <div class="save-tip-wrap">
        <div class="logo">
          <el-image
            :src="require('@/assets/images/zs.png')"
            :lazy="true"
          ></el-image>
        </div>
        <div class="tips">
          <p class="shaohou">正在另存所选数据，请稍后...</p>
          <p class="keyi">
            您可以离开此页面，另存将在后台进行，可前往 "<span
              @click="handleTokip"
              >我的文件夹</span
            >" 进行查看
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- 作者信息 -->
    <el-dialog class="author-dialog" title="个人信息" :visible.sync="visibleInfo" width="900px" append-to-body :close-on-click-modal="false">
      <div class="author-wrap" v-en-loading="authorLoading">
        <div class="author-basic">
          <!-- <el-image :src="author.Avatar" height="100%"></el-image> -->
          <el-avatar :src="author.Avatar" :size="100"></el-avatar>
          <div class="info">
            <p class="name">{{author.RealName || ""}}</p>
            <p class="company">{{author.Firm || ""}}</p>
            <p class="location">{{`所在城市：${author.Province || ""}${author.City || ""}`}}</p>
          </div>
        </div>
        <div class="author-brief" v-html="author.Resume"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lawService } from "@/api";

export default {
  name: "Detail",
  components: {
  },
  data() {
    return {
      visible: false,
      visibleInfo: false,
      loading: false,
      authorLoading: false,
      seParams: { id: "", userId: "" },
      detail: {},
      author: {}
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters("main", ["hasFolderPermission"]),
    // 真实有效的link数量
    showLinkCount() {
      if (!this.detail.MultipleTemplates) {
        return 0;
      }
      const textArray = this.detail.MultipleTemplates.filter(
        (data) => data.Number !== 0
      );
      return textArray.length;
    },
    chargesMode() {
      const { chargesMode } = this.$route.query;
      return chargesMode || "";
    }
  },
  mounted() {
    this.init(this.chargesMode);
  },
  methods: {
    async init(chargesMode) {
      try {
        this.loading = true;
        const { id, userId, price } = this.$route.query;
        const rsp = await lawService.getTemplate({
          id, userId, price, chargesMode
        });
        this.loading = false;
        this.detail = rsp || {};
      } catch (error) {
        this.loading = false;
        setTimeout(() => {
          window.close();
        }, 3000);
      }
    },
    // 另存为
    async handleSave() {
      this.visible = true;
      await lawService.saveTemplateToFolder({
        id: this.detail.Id,
        price: this.detail.Price,
        chargesMode: this.chargesMode
      });
    },
    // 跳转我的文件夹
    async handleTokip() {
      if (this.hasFolderPermission) {
        this.$router.push("/my-folder/index");
        return;
      }
      this.$message("您没有“我的文件夹”权限，请联系管理员开通权限");
    },
    // 下载到本地
    async handleDownload() {
      const param = {
        id: this.detail.Id,
        userId: this.userInfo?.userId,
        price: this.detail.Price,
        chargesMode: this.chargesMode,
        requestType: "stream"
      };
      const res = await lawService.downloadTemplate(param);

      const { headers } = res;
      const cd = headers["content-disposition"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/vnd.ms-word" })
      );
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
     // 查看作者详情
    async handleCheckAuthor() {
      try {
        this.visibleInfo = true;
        this.authorLoading = true;
        const rsp = await lawService.getAuthor({ id: this.detail.AuthorId });
        this.authorLoading = false;
        this.author = rsp;
      } catch (error) {
        this.authorLoading = true;
      }
    },
    // 链接跳转到关联的文本
    async handleJumpToLink(data) {
      if (data.Number === 0) {
        return;
      }
      try {
        this.loading = true;
        const { id } = this.$route.query;
        const rsp = await lawService.viewTemplate({ id });
        this.loading = false;
        const price = (rsp || {}).price;
        const handleJump = (price = 0) => {
          const newPage = this.$router.resolve({
            path: "/lawAngel/detail",
            query: {
              price,
              id: data.Id,
              userId: this.userInfo?.userId
            }
          });
          window.open(newPage.href, "_blank");
        };
        if (Number(price) > 0) {
          this.$confirm(
            `该文档为付费文档，查看该文档将可能从您企业账户扣除${price}元费用。您是否确定查看该文档?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              showCancelButton: true,
              type: "warning"
            }
          )
            .then(() => {
              handleJump(price);
            })
            .catch(() => {});
        } else {
          handleJump();
        }
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-wrap {
  color: #333333;
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/bjt.jpg") no-repeat;
  background-size: 100%;
  background-color: #ecf1f7;
  position: relative;
  font-size: 12px;
  .detail-box {
    width: 80%;
    height: calc(100% - 110px);
    background-color: #ffffff;
    z-index: 2;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
  }
  .operate-row {
    padding: 20px 0;
    text-align: center;
    @extend .flex-box;
    .avatar {
      height: 100%;
      cursor: pointer;
      .el-avatar {
        vertical-align: middle;
      }
      .author-name {
        vertical-align: middle;
        margin-left: 20px;
      }
    }
    .btn-group {
      flex: 1;
      text-align: right;
      .save {
        border-color: #26c393;
        color: #26c393;
      }
      .download {
        background: #26c393;
        color: #ffffff;
        border: none;
      }
    }
  }
  .basic {
    @extend .flex-box;
    align-items: flex-start;
    padding-top: 20px;
    .basic-left {
      flex: 1;
      text-align: left;
      .basic-title {
        font-size: 16px;
        font-weight: bold;
      }
      .basic-category {
        color: #7393bb;
        padding: 10px 0;
        > div {
          display: inline-block;
        }
        .cate-lis {
          margin-right: 20px;
        }
        .word-num {
          color: #636c78;
        }
      }
    }
    .basic-right {
      flex: 1;
      text-align: right;
      color: #91a1b7;
      .en-icon {
        font-size: 14px;
        margin-right: 5px;
        vertical-align: middle;
      }
      .time-label {
        vertical-align: middle;
      }
    }
  }
  .brief {
    padding: 10px 20px;
    color: #636c78;
    background-color: #f5f7fa;
    text-align: left;
  }
  .detail-content {
    margin-top: 20px;
    text-align: left;
  }
  .link {
    padding: 21px 19px;
    border: 1px solid #dce5ec;
    border-radius: 4px;
    text-align: left;
    .link-placeholder {
      .color-block {
        width: 3px;
        height: 14px;
        background: #3e90fe;
        border-radius: 1px;
        display: inline-block;
        vertical-align: middle;
      }
      .text-block {
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
      }
    }
    .link-bold {
      margin: 12px 0;
      font-weight: bold;
    }
    .link-normal {
      margin: 6px 0;
      text-decoration: underline;
      color: #636c78;
      &:hover {
        color: #3e90fe;
        cursor: pointer;
      }
    }
  }
}

.save-tip-alert {
  .save-tip-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    .tips {
      margin-left: 20px;
      text-align: left;
      .shaohou {
        font-weight: 600;
      }
      .keyi > span {
        cursor: pointer;
        color: #3e90fe;
      }
    }
  }
  & /deep/ .el-dialog__body {
    padding-bottom: 50px;
  }

}

.author-dialog{
    & /deep/ .el-dialog__title{
      font-size: 14px;
      font-weight: 600;
    }
    & /deep/ .el-dialog__body{
      padding: 10px 20px 20px 20px;
    }
    .author-wrap{
      font-size: 12px;
    }
    .author-basic{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .info{
        margin-left: 20px;
        .name{
          color:#333333;
        font-size: 16px;
        }
        .company{
          color: #636c78;
          margin: 5px 0;
        }
        .location{
          margin: 0;
          color: #91a1b7;
        }
      }
    }
    .author-brief{
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #f5f7fa;
    }
  }
</style>
<style lang="scss">
.detail-content {
  h1 {
    color: #333333;
  }
  p {
    color: #636c78;
    strong {
      color: #333333;
    }
  }
  table {
    border: 1px solid #000000;
    border-collapse: collapse;
    td {
      border: 1px solid #000000;
    }
  }
}
</style>
