<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:18:27
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-19 09:46:18
 * @Description: 法律知识库列表
-->
<template>
  <div class="container">
    <div class="left-menu" v-show="!isSearching" v-en-loading="meunLoading">
      <en-title-card name="文档目录"></en-title-card>
      <div class="menu-list">
        <collapse-tree v-for="(itm, idx) in cateList" :key="idx" :node="itm"></collapse-tree>
      </div>
    </div>
    <div class="right-list" v-en-loading="loading">
      <en-title-card name="合同分类">
        <template slot="right">
          <el-button v-if="!isSearching" size="small" :class="{'need-backgound': screenNum > 0}" class="btn-screen" icon="iconfont iconfenleishaixuan" @click="handleScreenClick">
            {{screenNum === 0 ? "分类筛选" : `${screenNum}项`}}
          </el-button>
        </template>
      </en-title-card>
      <div class="contract-wrap" ref="rightDom">
        <screen-model v-show="isScreening" @handleScreen="handleScreen"></screen-model>
        <en-result v-show="!modelList.length" type="NoData" :subTitle="subTitle"></en-result>
        <contract-item v-for="itm in modelList" :key="itm.id" :node="itm"></contract-item>
      </div>
      <en-pagination :page-model="pageModel" @change="handlePageChange"></en-pagination>
    </div>

    <!-- 全局搜索 -->
    <screen-alert ref="screen"></screen-alert>
    <!-- 作者信息 -->
    <el-dialog class="author-dialog" title="个人信息" :visible.sync="visible" width="900px" append-to-body :close-on-click-modal="false">
      <div class="author-wrap" v-en-loading="authorLoading">
        <div class="author-basic">
          <el-avatar :src="author.Avatar" :size="100"></el-avatar>
          <div class="info">
            <p class="name">{{author.RealName || ""}}</p>
            <p class="company">{{author.Firm || ""}}</p>
            <p class="location">{{`所在城市：${author.Province || ""}${author.City || ""}`}}</p>
          </div>
        </div>
        <div class="author-brief" v-html="author.Resume"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { lawService } from "@/api";
import collapseTree from "../components/collapseTree";
import contractItem from "../components/contractItem";
import screenAlert from "../components/screenAlert";
import screenModel from "../components/screenModel";

export default {
  name: "Home",
  components: {
    collapseTree, contractItem, screenAlert, screenModel
  },
  computed: {
    subTitle() {
      return this.isSearching ? "暂无内容，换个关键词试试~" : "很抱歉，当前无数据哟~";
    }
  },
  data() {
    return {
      loading: false,
      meunLoading: false,
      authorLoading: false,
      isSearching: false,
      isScreening: false,
      visible: false,
      cateList: [],
      screenNum: 0, // 筛选项数
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0
      },
      seParams: {
        pagesize: 10,
        page: 1,
        key: "",
        category: "",
        isMultiple: "",
        docType: "",
        isGovernment: ""
      },
      modelList: [],
      author: {}
    };
  },
  mounted() {
    this.$eventHub.$on("handleGlobalScreen", () => {
      this.$refs.screen.visible = true;
    });
    this.initMenuClass();
    this.queryFtsTempalte();
  },
  beforeDestroy() {
    this.$eventHub.$off("handleGlobalScreen");
  },
  provide() {
    return {
      $home: this
    };
  },
  methods: {
    async initMenuClass() {
      try {
        this.meunLoading = true;
        const rsp = await lawService.getTemplateCategory();
        this.meunLoading = false;
        const arr = cloneDeep(rsp);
        this.initLevel(arr, 0);
        this.initList(arr);
        this.cateList = arr;
      } catch (error) {
        this.meunLoading = false;
      }
    },
    // 给数据赋予层级
    initLevel(list, num) {
      list.forEach((itm) => {
        itm.level = num;
        if (itm.childrenList && itm.childrenList.length) {
          this.initLevel(itm.childrenList, num + 1);
        }
      });
    },
    // 初始化 文档目录数据
    initList(list) {
      list.forEach((itm) => {
        itm.cur = false;
        itm.open = false;
        if (itm.childrenList && itm.childrenList.length) {
          this.initList(itm.childrenList);
        }
      });
    },
    // 搜索模板
    async queryFtsTempalte() {
      try {
        this.loading = true;
        this.seParams.pagesize = this.pageModel.pageSize;
        this.seParams.page = this.pageModel.pageNo;
        const rsp = await lawService.searchTemplates(this.seParams);
        this.loading = false;
        this.pageModel.total = rsp.Total;
        this.pageModel.totalPages = Math.ceil(Number(rsp.Total) / this.pageModel.pageSize);
        this.modelList = [...rsp.Items];
        this.scrollTop();
      } catch (error) {
        this.loading = false;
      }
    },
    // 分类点击
    handleClassClick(id) {
      this.cateList.forEach((itm) => {
        this.$set(itm, "cur", itm.id === id);
        this.checKNodeCur(id, itm.childrenList);
      });
      this.seParams.category = id;
      // 重置 分页参数
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.queryFtsTempalte();
    },
    // 子目录点击
    handleNodeClick(id) {
      this.cateList.forEach((itm) => {
        this.$set(itm, "cur", false);
        this.checKNodeCur(id, itm.childrenList);
      });
      this.seParams.category = id;
      // 重置 分页参数
      Object.assign(this.pageModel, this.$options.data().pageModel);
      this.queryFtsTempalte();
    },
    //
    checKNodeCur(id, list) {
      list.forEach((itm) => {
        this.$set(itm, "cur", itm.id === id);
        if (itm.childrenList && itm.childrenList.length) {
          this.checKNodeCur(id, itm.childrenList);
        }
      });
    },
    // 分页器事件
    handlePageChange({ pageSize, pageNo }) {
      this.$set(this.pageModel, "pageNo", pageNo);
      this.$set(this.pageModel, "pageSize", pageSize);
      this.queryFtsTempalte();
    },
    // 作者信息
    async showAuthorInfo(id) {
      try {
        this.visible = true;
        this.authorLoading = true;
        const rsp = await lawService.getAuthor({ id });
        this.authorLoading = false;
        this.author = rsp;
      } catch (error) {
        this.authorLoading = true;
      }
    },
    // 分类筛选
    handleScreen(data) {
      let num = 0;
      Object.keys(data).forEach((itm) => {
        if (data[itm] !== "") {
          num++;
        }
      });
      this.screenNum = num;
      Object.assign(this.seParams, data);
      this.queryFtsTempalte();
    },
    // 分类筛选点击
    handleScreenClick() {
      this.isScreening = !this.isScreening;
      this.isScreening && this.scrollTop();
    },
    // 页面回到顶部
    scrollTop() {
      this.$refs.rightDom.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    toDetail(params) {
      window.open(params.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  color: #333333;
  width: 100%;
  height: 100%;
  display: flex;
  .left-menu {
    width: 322px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    margin-right: 10px;
    .menu-list {
      padding: 22px 20px;
      height: calc(100% - 50px);
      overflow: hidden;
      overflow-y: auto;
    }
  }
  .right-list {
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    flex: 1;
    overflow: hidden;
    & /deep/ .en-title-card-right-container{
      padding: 0;
    }
    .need-backgound {
      background-color:#3e90fe1A;
    }
    .btn-screen{
      border: none;
      font-size: 12px;
      color: #3e90fe;
      & /deep/ .iconfont{
        margin-right: 5px !important;
        vertical-align: middle;
      }
      & /deep/ span{
        vertical-align: middle;
      }
    }
    .contract-wrap {
      width: 100%;
      height: calc(100% - 105px);
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      transition: all .5s;
      .nodata {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .pagination {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.author-dialog{
  & /deep/ .el-dialog__title{
    font-size: 14px;
    font-weight: 600;
  }
  & /deep/ .el-dialog__body{
    padding: 10px 20px 20px 20px;
  }
  .author-wrap{
    font-size: 12px;
  }
  .author-basic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .info{
      margin-left: 20px;
      .name{
        color:#333333;
      font-size: 16px;
      }
      .company{
        color: #636c78;
        margin: 5px 0;
      }
      .location{
        margin: 0;
        color: #91a1b7;
      }
    }
  }
  .author-brief{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f5f7fa;
  }
}

</style>
