<!--
 * @Author: pengyu
 * @Date: 2021-02-25 09:54:28
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-10 09:27:37
 * @Description: 合同条目
-->
<template>
  <div class="contract-item" @click.stop="handleCheckDetail">
    <div class="contract-content">
      <div class="left">
        <div class="theme">{{ `#${node.Number} ${node.Title}` }}</div>
        <div class="tag"></div>
        <div class="brief" v-html="node.Brief">
          <!-- {{ node.Brief }} -->
        </div>
        <div class="time">
          <en-icon name="shijian-zhuti" style="width:16px; height:16px"></en-icon>
          <span>{{ node.PublishedOn.slice(0, 10) }}</span>
        </div>
      </div>
      <div class="right">
        <div class="author" v-show="node.AuthorId" @click.stop="handleCheckAuthor">
          <div class="author-log">
            <el-avatar :src="node.AuthorAvatar" :size="26"></el-avatar>
          </div>
          <div class="author-name">{{ node.AuthorName }}</div>
        </div>
        <div class="price" v-if="Number(node.Price)">￥{{ node.Price ? Number(node.Price).toFixed(2) : "" }}</div>
      </div>
    </div>
    <div class="contract-border"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lawService } from "@/api";

export default {
  name: "ContractItem",
  props: {
    node: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  inject: ["$home"],
  data() {
    return {};
  },
  methods: {
    // 查看文档详情
    async handleCheckDetail() {
      const price = this.node.Price;
      let newPage = this.$router.resolve({ name: "Detail", path: "/lawAngel/detail", query: { id: this.node.Id, userId: this.userInfo?.userId, price } });
      if (Number(price) > 0) {
        const rsp = await this.queryFtsChargeMode();
        const account = rsp.chargesMode === 1 ? "企业账户" : "个人账户";
        const color = rsp.chargesMode === 1 ? "#3D96D8" : "#FF973C";
        newPage = this.$router.resolve({
          path: "/lawAngel/detail",
          query: {
            id: this.node.Id, userId: this.userInfo?.userId, price, chargesMode: rsp.chargesMode
          }
        });

        this.$confirm(`该文档为付费文档，查看该文档将可能从您的<span style="color:${color};"> ${account} </span>扣除${price}元费用。您确定查看该文档?`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          type: "info",
          iconClass: "iconfont icontishi",
          customClass: "confirm-alert"
        }).then(() => {
          this.toDetail(newPage);
        }).catch(() => {});
      } else {
          this.toDetail(newPage);
      }
    },
    toDetail(params) {
      this.$home.toDetail(params);
    },
    // 查看作者详情
    handleCheckAuthor() {
      this.$home.showAuthorInfo(this.node.AuthorId);
    },
    // 查询法天使收费方式
    async queryFtsChargeMode() {
      const rsp = await lawService.queryFtsChargeMode();
      return rsp;
    }
  }
};
</script>

<style lang="scss" scoped>
.contract-item {
  color: #333333;
  padding: 20px 20px 0 20px;
  font-size: 12px;

  &:hover {
    background: #f5f8fc;
  }
  .contract-content {
    display: flex;
    justify-content: space-between;
  }
  .left {
    text-align: left;
    flex: 1;
    .theme {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 10px;
    }
    .brief {
      color: #636c78;
      padding: 5px 0;
      min-height: 26px;
    }
    .time {
      padding-top: 10px;
      color: #91a1b7;
      .en-icon,
      span {
        vertical-align: middle;
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .right {
    width: 126px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    .author {
      width: 104px;
      height: 26px;
      color: #636c78;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #eef6fe;
      box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.1);
      border-radius: 3px;
      border: 1px solid #eee;
      border-top-left-radius: 13px;
      border-bottom-left-radius: 13px;
      .author-log {
        width: 26px;
        height: inherit;
        border-radius: 50%;
        overflow: hidden;
      }
      .author-name {
        flex: 1;
        height: inherit;
        line-height: 26px;
        font-size: 12px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      &:hover {
        border-color: #3e90fe;
        color: #3e90fe;
      }
    }
    .price {
      width: 72px;
      padding: 5px 0px;
      background: #f78528;
      color: #fff;
      border-radius: 3px;
      text-align: center;
    }
  }
  .contract-border {
    padding-top: 20px;
    border-bottom: 1px solid #e8ecf2;
  }
}
</style>
<style>
.confirm-alert .el-message-box__status{
  color:#4592DC ;
}
</style>
