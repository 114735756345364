var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "screen-wrap" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "60px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "文档类型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.docType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "docType", $$v)
                    },
                    expression: "form.docType",
                  },
                },
                _vm._l(_vm.category, function (itm) {
                  return _c("el-checkbox", {
                    key: itm.key,
                    attrs: { label: itm.label },
                    on: { change: (value) => _vm.hanleChange1(value, itm.key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "成套文本" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.isMultiple,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isMultiple", $$v)
                    },
                    expression: "form.isMultiple",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { label: true },
                      on: { change: (value) => _vm.hanleChange2(value, true) },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { label: false },
                      on: { change: (value) => _vm.hanleChange2(value, false) },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "政府示范" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.isGovernment,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isGovernment", $$v)
                    },
                    expression: "form.isGovernment",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { label: true },
                      on: { change: (value) => _vm.hanleChange3(value, true) },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { label: false },
                      on: { change: (value) => _vm.hanleChange3(value, false) },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }