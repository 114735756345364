var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-wrap" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "detail-box",
        },
        [
          _c("div", { staticClass: "operate-row" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detail.AuthorId,
                    expression: "detail.AuthorId",
                  },
                ],
                staticClass: "avatar",
              },
              [
                _c("el-avatar", {
                  attrs: {
                    src: _vm.detail.AuthorAvatar,
                    size: 100,
                    fit: "cover",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleCheckAuthor.apply(null, arguments)
                    },
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "author-name",
                    staticStyle: { "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(_vm.detail.AuthorName || ""))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btn-group" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "save",
                    staticStyle: { width: "72px" },
                    attrs: {
                      size: "small",
                      disabled: Object.keys(_vm.detail).length === 0,
                    },
                    on: { click: _vm.handleSave },
                  },
                  [_vm._v("另存")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "download",
                    attrs: {
                      size: "small",
                      disabled: Object.keys(_vm.detail).length === 0,
                    },
                    on: { click: _vm.handleDownload },
                  },
                  [_vm._v("下载到本地")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "basic" }, [
            _c("div", { staticClass: "basic-left" }, [
              _c("div", { staticClass: "basic-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `#${_vm.detail.Number || ""} ${_vm.detail.Title || ""}`
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "basic-category" }, [
                _c("div", { staticClass: "cate-lis" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.TemplateCategoryNames
                          ? _vm.detail.TemplateCategoryNames.join(" ")
                          : ""
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "word-num" },
                  [
                    _c("en-icon", {
                      attrs: { name: "zi", color: "#26c393", size: "mini" },
                    }),
                    _vm._v(" " + _vm._s(_vm.detail.Words || "") + " "),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "basic-right" },
              [
                _c("en-icon", {
                  attrs: { name: "shijian-zhuti", size: "mini" },
                }),
                _c("span", { staticClass: "time-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.detail.PublishedOn
                        ? _vm.detail.PublishedOn.slice(0, 10)
                        : ""
                    )
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c("div", {
            staticClass: "brief",
            domProps: { innerHTML: _vm._s(_vm.detail.Brief) },
          }),
          _c("div", {
            staticClass: "detail-content",
            domProps: { innerHTML: _vm._s(_vm.detail.Content) },
          }),
          _vm.showLinkCount > 0
            ? _c(
                "div",
                { staticClass: "link" },
                [
                  _c("div", { staticClass: "link-placeholder" }, [
                    _c("span", { staticClass: "color-block" }),
                    _c("span", { staticClass: "text-block" }, [
                      _vm._v(_vm._s(`包含${_vm.showLinkCount}个文本`)),
                    ]),
                  ]),
                  _vm._l(_vm.detail.MultipleTemplates, function (data, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: {
                          "link-bold": data.Number === 0,
                          "link-normal": data.Number !== 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleJumpToLink(data)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.Number === 0
                                ? data.Title
                                : `#${data.Number} ${data.Title}`
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "save-tip-alert",
          attrs: { visible: _vm.visible, width: "700px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "save-tip-wrap" }, [
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("el-image", {
                  attrs: { src: require("@/assets/images/zs.png"), lazy: true },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "tips" }, [
              _c("p", { staticClass: "shaohou" }, [
                _vm._v("正在另存所选数据，请稍后..."),
              ]),
              _c("p", { staticClass: "keyi" }, [
                _vm._v(' 您可以离开此页面，另存将在后台进行，可前往 "'),
                _c("span", { on: { click: _vm.handleTokip } }, [
                  _vm._v("我的文件夹"),
                ]),
                _vm._v('" 进行查看 '),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "author-dialog",
          attrs: {
            title: "个人信息",
            visible: _vm.visibleInfo,
            width: "900px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleInfo = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.authorLoading,
                  expression: "authorLoading",
                },
              ],
              staticClass: "author-wrap",
            },
            [
              _c(
                "div",
                { staticClass: "author-basic" },
                [
                  _c("el-avatar", {
                    attrs: { src: _vm.author.Avatar, size: 100 },
                  }),
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.author.RealName || "")),
                    ]),
                    _c("p", { staticClass: "company" }, [
                      _vm._v(_vm._s(_vm.author.Firm || "")),
                    ]),
                    _c("p", { staticClass: "location" }, [
                      _vm._v(
                        _vm._s(
                          `所在城市：${_vm.author.Province || ""}${
                            _vm.author.City || ""
                          }`
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", {
                staticClass: "author-brief",
                domProps: { innerHTML: _vm._s(_vm.author.Resume) },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }